import { Button } from "@/components/ui/button";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { DialogDescription, DialogTitle } from "@/components/ui/dialog";
import { useAccount } from "@/hooks/use-account";
import { useTranslations } from "@/i18n";
import { getSearchProducts } from "@/services/search";
import type { Supplier } from "@/services/supplier";
import type { Lang } from "@/types";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { useBoolean, useRequest } from "ahooks";
import { CommandLoading } from "cmdk";
import { Loader2Icon, SearchIcon } from "lucide-react";
import { useState } from "react";
import { openQuickView } from "../quick-view/quick-view.store";
import { searchProductToCatalogProduct } from "../mappers";
import { formatCurrency } from "@/lib/format-currency";
import { cn } from "@/lib/utils";
import { ImageWithFallback } from "@/components/ImageWithFallback";

export const ProductsSearch = ({
  lang,
  supplier,
}: {
  lang: Lang;
  supplier: Supplier;
  variant: "mobile" | "desktop";
}) => {
  const t = useTranslations(lang);
  const [open, { set, setTrue, setFalse }] = useBoolean(false);

  return (
    <>
      <SearchTrigger lang={lang} setOpen={setTrue} />

      <CommandDialog
        open={open}
        onOpenChange={set}
        commandProps={{ shouldFilter: false }}
      >
        <VisuallyHidden>
          <DialogTitle>{t("common.search")}</DialogTitle>
          <DialogDescription>{t("common.search")}</DialogDescription>
        </VisuallyHidden>

        <SearchDialogContent
          lang={lang}
          supplier={supplier}
          onClose={setFalse}
        />
      </CommandDialog>
    </>
  );
};

const SearchTrigger = ({
  lang,
  setOpen,
}: {
  lang: Lang;
  setOpen: () => void;
}) => {
  const t = useTranslations(lang);

  return (
    <>
      <Button
        size="icon"
        variant="outline"
        className="md:hidden"
        onClick={setOpen}
      >
        <SearchIcon className="w-4 h-4" />
      </Button>

      <Button
        variant="outline"
        className="hidden md:flex justify-between min-w-52 lg:min-w-64 xl:min-w-96"
        onClick={setOpen}
      >
        <span>{t("common.search")}...</span>
        <SearchIcon className="w-4 h-4" />
      </Button>
    </>
  );
};

const SearchDialogContent = ({
  lang,
  supplier,
  onClose,
}: {
  lang: Lang;
  supplier: Supplier;
  onClose: () => void;
}) => {
  const t = useTranslations(lang);
  const { account } = useAccount();
  const [query, setQuery] = useState("");

  const veryFewCharacters = query.length < 3;

  const { data, loading } = useRequest(
    async () =>
      getSearchProducts({
        name: query,
        supplierId: supplier.id,
        brandId: [],
        categoryId: [],
        segmentId: account?.suppliers.find((s) => s.supplierId === supplier.id)
          ?.segments[0]?.id,
        hasStock: null,
        hasOffer: null,
        status: true,
        page: 0,
        size: 10,
        sort: {
          field: "name",
          sort: "asc",
        },
      }).then((data) => ({
        ...data,
        content: data.content.map((p) =>
          searchProductToCatalogProduct(p, supplier, lang),
        ),
      })),
    {
      ready: !veryFewCharacters,
      refreshDeps: [query, account?.id],
      debounceLeading: true,
      debounceWait: 400,
      cacheKey: `search:${query}`,
    },
  );

  return (
    <>
      <CommandInput
        placeholder={t("common.search") + "..."}
        value={query}
        onValueChange={setQuery}
      />

      <CommandList>
        {!veryFewCharacters && !data && loading && (
          <CommandLoading className="flex justify-center py-2">
            <Loader2Icon className="animate-spin h-4 w-4" />
          </CommandLoading>
        )}

        {veryFewCharacters ? (
          <CommandEmpty>{t("common.veryFewCharacters")}</CommandEmpty>
        ) : !data || data.content.length === 0 ? (
          <CommandEmpty>{t("common.noResults")}</CommandEmpty>
        ) : (
          data.content.length > 0 && (
            <CommandGroup
              heading={
                <div className="flex gap-2 items-center">
                  <div>Resultados</div>
                  {loading && <Loader2Icon className="animate-spin h-4 w-4" />}
                </div>
              }
            >
              {data?.content.map((product) => (
                <CommandItem
                  key={product.id}
                  onSelect={() => {
                    onClose();
                    openQuickView(product);
                  }}
                >
                  <article className="flex gap-4 items-center">
                    <ImageWithFallback
                      src={product.image}
                      alt={product.name}
                      className="w-10 h-10 flex-none object-cover border rounded-lg"
                    />
                    <div
                      className={cn("flex flex-col text-one", {
                        "opacity-50": product.stock <= 0,
                      })}
                    >
                      <div>{product.name}</div>
                      <div className="font-medium">
                        {formatCurrency(
                          product.price,
                          lang,
                          supplier.countryCode,
                        )}
                      </div>
                    </div>
                  </article>
                </CommandItem>
              ))}
            </CommandGroup>
          )
        )}
      </CommandList>
    </>
  );
};
